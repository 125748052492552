import { UsersStore } from '../localStorage';
import Vue from 'vue';
import { Notify } from 'quasar';
import axios from 'axios';
import { getApiExportBaseUrl } from '@/shared/utils/helpers';
var EndPoint;
(function (EndPoint) {
    EndPoint["PLAN_INFORMATION"] = "user/account";
    EndPoint["USUARIOS_GERENCIADORES"] = "user/supervised";
    EndPoint["USER_ME"] = "user/me";
    EndPoint["USER_NEW_PASSWORD"] = "user/new_password";
    EndPoint["USER"] = "user";
    EndPoint["EDIT_USER"] = "edit/user";
    EndPoint["USER_RECOVER_PASSWORD"] = "user/recover_password";
    EndPoint["USER_RESET"] = "user/reset_password";
    EndPoint["USER_TOKEN_SALESFORCE"] = "app/salesforce";
    //USER_SALESFORCE_JOB = 'https://na123.salesforce.com/services/data/v49.0/jobs/ingest'
    EndPoint["USER_SALESFORCE_JOB"] = "/services/data/v49.0/jobs/ingest";
    EndPoint["BASE_PLOOMES"] = "https://api2.ploomes.com";
    EndPoint["USER_TOKEN_HUBSPOT"] = "";
    //USER_SALESFORCE_JOB = 'https://na139.salesforce.com/services/data/v49.0/jobs/ingest'
    EndPoint["USER_SURVEY_GREATER_SEVEN"] = "user/made_survey_greater_than_seven";
    EndPoint["USER_UPDATE_STATUS_SPEEDMAIL_MODAL_PROMO"] = "speedmail/save_status_no_show_modal_again";
    EndPoint["USER_AVAILABLE_FOR_SPM_PROMO"] = "speedmail/show_modal";
    EndPoint["SUGGEST_SPEEDMAIL"] = "user/suggest_speedmail";
    EndPoint["SHOW_MODAL_EXPERIENCE_HYPERBOLIC"] = "user/show_hyperbolic_tree_survey";
    EndPoint["EXPERIENCE_USER_HYPERBOLIC"] = "user/register_survey ";
    // DDCS KA
    EndPoint["TRIED_TO_REGISTER_NEW_ADDITIONAL_USER"] = "ddcs/ka/tried_to_register_new_additional_user";
    EndPoint["TRIED_TO_IMPORT_WITHOUT_CREDITS"] = "ddcs/ka/tried_to_import_without_credits";
    EndPoint["ENTER_SM_CONFIG"] = "ddcs/ka/first_sm_campaign";
    EndPoint["CLICKED_ON_UPGRADE"] = "ddcs/ka/clicked_on_upgrade";
    EndPoint["SCHEDULE_DEMO"] = "ddcs/ka/scheduled_demo";
    // WELCOME SCREEN
    EndPoint["DISPLAY_WELCOME_SCREEN"] = "welcome_screen/should_show_welcome_screen";
    EndPoint["WELCOME_SCREEN_SEARCH"] = "welcome_screen/insert_answers";
    EndPoint["AUTO_PLAY_CRMTOKEN"] = "user/register_crm";
    EndPoint["SHOW_AUTOPLAY"] = "user/autoplay/show_survey";
    EndPoint["USER_NOTES"] = "user/notes/";
})(EndPoint || (EndPoint = {}));
export const usersApi = {
    //GET USERS GERENCIADOR
    async getSubUsers() {
        return await Vue.axios
            .get(`${EndPoint.USUARIOS_GERENCIADORES}`)
            .then(res => res)
            .catch(err => err);
    },
    //GET SALESFORCE TOKEN
    async getApiTokenSalesForce(payload) {
        /*payload['redirect_uri'] =
          'http://localhost:8080/user/config/api-token-export/salesforce/callback';*/
        return await Vue.axios
            .post(`${EndPoint.USER_TOKEN_SALESFORCE}`, payload)
            .then(res => res)
            .catch(err => err.response);
    },
    async getApiTokenHubSpot(payload) {
        return await Vue.axios
            //.post('https://api.hubapi.com/oauth/v1/token', payload)
            //.post(`http://localhost:3333/user/hubspot/token`, payload)
            .post(`${getApiExportBaseUrl()}/user/hubspot/token`, payload)
            .then(res => res)
            .catch(err => err.response);
    },
    async getApiTokenZoho(payload) {
        return await Vue.axios
            .post(`${getApiExportBaseUrl()}/user/zoho/token`, payload)
            .then(res => res)
            .catch(err => err.response);
    },
    async validUrlBitrix24(payload) {
        return await Vue.axios
            // .post(`http://localhost:3333/user/bitrix24/url`, payload)
            .post(`${getApiExportBaseUrl()}/user/bitrix24/url`, payload)
            .then(res => res)
            .catch(err => err.response);
    },
    //GET RDSTATION TOKEN
    async setApiTokenRdSTation(payload) {
        return await axios
            .post('https://api.rd.services/auth/token', payload)
            .then(res => res)
            .catch(err => err.response);
    },
    //GET TOKEN PIPEDRIVE
    async getApiTokenPipedrive(token) {
        return await axios
            .get(`https://companydomain.pipedrive.com/v1/activities?start=0&api_token=${token}`)
            .then(res => res)
            .catch(err => err);
    },
    async getApiTokenMeetime(token) {
        return await axios
            .get('https://api.meetime.com.br/v2/company', {
            headers: {
                Authorization: token,
            },
        })
            .then(res => res)
            .catch(err => err);
    },
    //GET TOKEN PIPERUNS
    async getApiTokenPiperun(token) {
        return await Vue.axios
            // .post(`http://localhost:3333/user/piperun/token`, { token: token })
            .post(`${getApiExportBaseUrl()}/user/piperun/token`, { token: token })
            .then(res => res)
            .catch(err => err.response);
    },
    //GET TOKEN AGENDOR
    async getApiTokenAgendor(token) {
        return await Vue.axios
            // .post(`http://localhost:3333/user/agendor/token`, { token: token })
            .post(`${getApiExportBaseUrl()}/user/agendor/token`, { token: token })
            .then(res => res)
            .catch(err => err.response);
    },
    //GET TOKEN PLOOMES
    async getApiTokenPloomes(token) {
        return await axios
            .get('https://api2.ploomes.com/Self', {
            headers: {
                'User-Key': token,
            },
        })
            .then(res => res)
            .catch(err => err);
    },
    //POST CONTACT PLOOMES
    async setContactPloomes(token, data) {
        // eslint-disable-next-line @typescript-eslint/no-inferrable-types
        let count = 0;
        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
        for (let i = 0; i < data.length; i++) {
            count++;
            if (count <= 19) {
                axios
                    .post('https://api2.ploomes.com/Contacts', data[i], {
                    headers: {
                        'User-Key': token,
                    },
                })
                    .then(res => res)
                    .catch(err => err);
            }
            else {
                await sleep(10000);
                count = 0;
                i--;
            }
        }
        Notify.create({
            message: 'Os dados da empresa foram enviados para sua conta ploomes...',
            type: 'exportation-success',
        });
    },
    //POST FIELDs PLOOMES
    async setFieldPloomes(token, data) {
        return await axios
            .post(`${EndPoint.BASE_PLOOMES}/Fields`, data, {
            headers: {
                'User-Key': token,
            },
        })
            .then(res => {
            // axios.post(`${EndPoint.BASE_PLOOMES}/Forms@Fields`)
        })
            .catch(err => err);
    },
    //GET FIELDs PLOOMES
    async getFieldsPloomes(token) {
        return await axios
            .get(`${EndPoint.BASE_PLOOMES}/Fields`, {
            headers: {
                'User-Key': token,
            },
        })
            .then(res => res)
            .catch(err => err);
    },
    async exportToSalesForce({ token, infosetId, payload: { service, filters, fields }, }) {
        // const url = `http://localhost:3333/export/${infosetId}`
        const url = `${getApiExportBaseUrl()}/export/${infosetId}/salesforce`;
        return await Vue.axios
            .post(url, {
            service,
            filters,
            fields,
            token,
        })
            .then(res => res)
            .catch(err => err.response);
    },
    async exportToAgendor({ token, infosetId, payload: { service, filters, fields }, }) {
        // const url = `http://localhost:3333/export/${infosetId}/agendor`
        const url = `${getApiExportBaseUrl()}/export/${infosetId}/agendor`;
        return await Vue.axios
            .post(url, {
            service,
            filters,
            fields,
            token,
        })
            .then(res => res)
            .catch(err => err.response);
    },
    async exportToPiperun({ token, infosetId, payload: { service, filters, fields }, }) {
        // const url = `http://localhost:3333/export/${infosetId}/piperun`
        const url = `${getApiExportBaseUrl()}/export/${infosetId}/piperun`;
        return await Vue.axios
            .post(url, {
            service,
            filters,
            token,
            fields,
        })
            .then(res => res)
            .catch(err => err.response);
    },
    async exportToPipedrive({ token, infosetId, payload: { service, filters, fields }, }) {
        // const url = `http://localhost:3333/export/${infosetId}/pipedrive`
        const url = `${getApiExportBaseUrl()}/export/${infosetId}/pipedrive`;
        return await Vue.axios
            .post(url, {
            service,
            filters,
            fields,
            token,
        })
            .then(res => res)
            .catch(err => err.response);
    },
    async exportToHubspot({ token, infosetId, payload: { service, filters, fields }, }) {
        // const url = `http://localhost:3333/export/${infosetId}/hubspot`
        const url = `${getApiExportBaseUrl()}/export/${infosetId}/hubspot`;
        return await Vue.axios
            .post(url, {
            service,
            filters,
            fields,
            token,
        })
            .then(res => res)
            .catch(err => err.response);
    },
    async exportToBitrix24({ infosetId, payload: { service, filters, token, fields }, }) {
        // const endPoint = `http://localhost:3333/export/${infosetId}/bitrix24`
        const endPoint = `${getApiExportBaseUrl()}/export/${infosetId}/bitrix24`;
        return await Vue.axios
            .post(endPoint, { service, filters, token, fields })
            .then(res => res)
            .catch(err => err.response);
    },
    async exportToPloomes({ token, infosetId, payload: { service, filters, fields }, }) {
        // const url = `http://localhost:3333/export/${infosetId}/ploomes`
        const url = `${getApiExportBaseUrl()}/export/${infosetId}/ploomes`;
        return await Vue.axios
            .post(url, {
            service,
            filters,
            fields,
            token,
        })
            .then(res => res)
            .catch(err => {
            return err;
        });
    },
    async exportToRDStation({ token, infosetId, payload: { service, filters, fields }, }) {
        // const url = `http://localhost:3333/export/${infosetId}/rdstation`
        const url = `${getApiExportBaseUrl()}/export/${infosetId}/rdstation`;
        return await Vue.axios
            .post(url, {
            service,
            filters,
            fields,
            token,
        })
            .then(res => res)
            .catch(err => err.response);
    },
    async validRDStationToken(token) {
        // const url = `http://localhost:3333/user/rdstation/token`
        const url = `${getApiExportBaseUrl()}/user/rdstation/token`;
        return Vue.axios
            .post(url, { token })
            .then(res => res)
            .catch(err => err);
    },
    async createUser(user) {
        return Vue.axios
            .post(`${EndPoint.USER}/sub_user`, user)
            .then(res => res)
            .catch(err => err);
    },
    async removeUser(userId) {
        return Vue.axios
            .delete(`${EndPoint.USER}/sub_user/${userId}`)
            .then(res => res)
            .catch(err => err);
    },
    async updateUser(userId, payload) {
        return Vue.axios
            .patch(`${EndPoint.USER}/sub_user/${userId}`, payload)
            .then(res => res)
            .catch(err => err);
    },
    //PATCH USER INFORMATION
    async changePassword(payload) {
        return Vue.axios
            .patch(`${EndPoint.USER_NEW_PASSWORD}`, payload)
            .then(res => res)
            .catch(err => err);
    },
    //GET USER INFORMATION
    async getUserInformation() {
        const mapResult = (res) => {
            const accountData = res.data.account;
            return {
                ...res,
                credits: res.data.credits,
                account: {
                    _id: accountData._id.$oid,
                    active: accountData.active,
                    createdAt: accountData.created_at,
                    currentClickCredits: accountData.current_click_credits,
                    currentExportCredits: accountData.current_export_credits,
                    excludedCnpjs: accountData.excluded_cnpjs,
                    maxUsersCount: accountData.max_users_count,
                    planName: accountData.plan_name,
                    unlimitedCredits: accountData.unlimited_credits,
                    updatedAt: accountData.updated_at,
                },
            };
        };
        return await Vue.axios
            .get(`${EndPoint.PLAN_INFORMATION}`)
            .then(res => mapResult(res))
            .catch(err => err);
    },
    //GET USER ME
    async getUserMe() {
        return await Vue.axios
            .get(`${EndPoint.USER_ME}`)
            .then(res => res)
            .catch(err => err);
    },
    async getBasicLoginInformation() {
        return await Vue.axios
            .get(`${EndPoint.USER}/basic_information`)
            .then(res => res)
            .catch(err => err);
    },
    //GET USER ME
    async recoverPassword(payload) {
        return await Vue.axios
            .post(`${EndPoint.USER_RECOVER_PASSWORD}`, payload)
            .then(res => res)
            .catch(err => err);
    },
    async resetPassword(payload) {
        return await Vue.axios
            .post(`${EndPoint.USER_RESET}`, payload)
            .then(res => res)
            .catch(err => err);
    },
    async getNotifications(userId, page = 1) {
        return await Vue.axios
            .get(`user/${userId}/notifications`, {
            params: {
                page,
            },
        })
            .then(res => res)
            .catch(err => err);
    },
    async markNotificationsAsRead(userId, notificationId) {
        return await Vue.axios
            .post(`user/${userId}/notifications/${notificationId}`)
            .then(res => res)
            .catch(err => {
            throw err;
        });
    },
    async answerSurvey(survey) {
        return await Vue.axios
            .post(`${EndPoint.USER}/survey`, survey)
            .then(res => res)
            .catch((err) => err.response);
    },
    async createAttemptsLog(attemptsLog) {
        return await Vue.axios
            .post(`${EndPoint.USER}/register_survey_user_action`, attemptsLog)
            .then(res => res)
            .catch((err) => err.response);
    },
    async showSurveyModal() {
        return await Vue.axios
            .get(`${EndPoint.USER}/check_permission_to_show_survey`)
            .then(res => res)
            .catch((err) => err.response);
    },
    async getAllSurveyQuestions() {
        return await Vue.axios
            .get(`${EndPoint.USER}/survey_questions`)
            .then(res => res)
            .catch((err) => err.response);
    },
    async onboardingRegister({ comment, selectedOption, emailUser, responsibleSelectedCsMember, creationOfOnboardingByAdmin, }) {
        const handleDateNow = new Date().toJSON().split('T')[0].replaceAll('-', '.');
        const onboarding = {
            comment,
            user_selected_option: selectedOption,
            user_selected_option_at: handleDateNow,
            email_user: emailUser,
            responsible_id: responsibleSelectedCsMember,
            creation_of_onboarding_by_admin: creationOfOnboardingByAdmin,
        };
        return await Vue.axios
            .post(`${EndPoint.USER}/onboarding`, { onboarding })
            .then(res => res)
            .catch((err) => err.response);
    },
    async showOnBoardingModal() {
        return await Vue.axios
            .get(`${EndPoint.USER}/check_permission_to_show_onboarding`)
            .then((res) => res)
            .catch((err) => err.response);
    },
    async rememberMeLater({ user_id, onboarding_update, }) {
        return await Vue.axios
            .put(`${EndPoint.USER}/${user_id}/onboarding_update_remember_me`, {
            onboarding_remember_me: onboarding_update,
        })
            .then(res => res)
            .catch((err) => err.response);
    },
    async showOnboardingNotification() {
        return await Vue.axios
            .get(`${EndPoint.USER}/check_permission_to_show_onboarding_notification`)
            .then(res => res)
            .catch((err) => err.response);
    },
    async handleVoucherVisibility() {
        return await Vue.axios
            .get(`${EndPoint.USER}/check_permission_to_show_voucher`)
            .then(res => res)
            .catch((err) => err.response);
    },
    async rescueVoucher({ allowedVoucher, voucherAction, surveyId, }) {
        return await Vue.axios
            .put(`${EndPoint.USER}/survey/update_user_voucher_permission`, {
            allowed_voucher: allowedVoucher,
            voucher_action: voucherAction,
            survey_id: surveyId,
        })
            .then(res => res)
            .catch((err) => err.response);
    },
    async getExportationFieldsAndFilters() {
        return await Vue.axios
            .get(`${EndPoint.USER}/exportation_filters_and_fields`)
            .then(res => res)
            .catch((err) => err.response);
    },
    async saveExportationFieldsAndFilters(config) {
        return await Vue.axios
            .put(`${EndPoint.USER}/exportation_filters_and_fields`, config)
            .then(res => res)
            .catch((err) => err.response);
    },
    async deleteExportationFieldsAndFiltersSaved(userId) {
        return await Vue.axios
            .delete(`${EndPoint.USER}/${userId}/exportation_filters_and_fields`)
            .then(res => res)
            .catch((err) => err.response);
    },
    async getUserSurveyGreaterThanSeven() {
        return await Vue.axios
            .get(EndPoint.USER_SURVEY_GREATER_SEVEN)
            .then(res => res)
            .catch((err) => err.response);
    },
    async getUserAccountPreferences() {
        return await Vue.axios
            .get('account_preferences')
            .then(res => res)
            .catch((err) => err.response);
    },
    async setUserAccountPreferences(data) {
        return await Vue.axios
            .patch('account_preferences', data)
            .then(res => res)
            .catch((err) => err.response);
    },
    async getUserRefreshToken(userToken) {
        return await Vue.axios
            .post(`${EndPoint.USER}/refresh_token`, {
            headers: {
                Authorization: 'Bearer ' + userToken,
            },
        })
            .then(res => res)
            .catch((err) => err.response);
    },
    async getApiTokenFunilDeVendas(tokenFunilDeVendas) {
        return await axios
            .post(`https://app.funildevendas.com.br/api/opportunity?IntegrationKey=${tokenFunilDeVendas}`)
            .then(res => res)
            .catch(err => err);
    },
    //GET TOKEN TOTVS
    async getApiTokenToTvs({ accessToken = '', integrationToken = '', }) {
        return await Vue.axios
            .post(`${getApiExportBaseUrl()}/user/totvs/token`, {
            accessToken: accessToken,
            integrationToken: integrationToken,
        })
            .then(res => res)
            .catch(err => err);
    },
    async exportToFunilDeVendas({ token, infosetId, payload: { service, filters, fields }, codsFunilDeVendas, }) {
        const url = `${getApiExportBaseUrl()}/export/${infosetId}/funildevendas`;
        return await Vue.axios
            .post(url, {
            service,
            filters,
            token,
            fields,
            cods: codsFunilDeVendas,
        })
            .then(res => res)
            .catch(err => err.response);
    },
    async exportToToTvs({ accessTokenToTvs, integrationTokenToTvs, infosetId, payload: { service, filters, fields }, }) {
        const url = `${getApiExportBaseUrl()}/export/${infosetId}/totvs`;
        return await Vue.axios
            .post(url, {
            service,
            filters,
            fields,
            accessTokenToTvs,
            integrationTokenToTvs,
        })
            .then(res => res)
            .catch(err => err.response);
    },
    async exportToZoho({ token, infosetId, payload: { service, filters, fields }, }) {
        const url = `${getApiExportBaseUrl()}/export/${infosetId}/zoho`;
        return await Vue.axios
            .post(url, {
            service,
            filters,
            fields,
            token,
        })
            .then(res => res)
            .catch(err => err.response);
    },
    async exportToMeetime({ token, infosetId, payload: { service, filters, fields }, cadenceApiUrl, ownerSelected, }) {
        const url = `${getApiExportBaseUrl()}/export/${infosetId}/meetime`;
        return await Vue.axios
            .post(url, {
            service,
            filters,
            fields,
            token,
            cadenceApiUrl,
            ownerSelected,
        })
            .then(res => res)
            .catch(err => err.response);
    },
    async tryCreateNewAccount(data) {
        return await Vue.axios
            .post('auth/signup_with_whatsapp', data)
            .then(res => res)
            .catch((err) => err.response);
    },
    async tryCreateAuthToken(data) {
        return await Vue.axios
            .post('auth/generate_authentication_code', data)
            .then(res => res)
            .catch((err) => err.response);
    },
    async tryAuthLogin(data) {
        return await Vue.axios
            .post('auth/signin_with_code_or_password', data)
            .then(res => res)
            .catch((err) => err.response);
    },
    async createAccountWithSocialMedia(data) {
        return await Vue.axios
            .post('auth/signup_with_social_midia', data)
            .then(res => res)
            .catch((err) => err.response);
    },
    async getUserExists(data) {
        return await Vue.axios
            .get('auth/check_if_account_already_exist', { params: data })
            .then(res => res)
            .catch((err) => err.response);
    },
    async checkUserCurrentJorney() {
        return await Vue.axios
            .get('user/check_current_journey')
            .then(res => res)
            .catch((err) => err.response);
    },
    async updateOnboardingUserJorney(data) {
        return await Vue.axios
            .post('user/update_crm_data', data)
            .then(res => res)
            .catch((err) => err.response);
    },
    async sendWhatsappConfirmationUserJorney(data) {
        return await Vue.axios
            .post('user/send_whatsapp_confirmation_code', data)
            .then(res => res)
            .catch((err) => err.response);
    },
    async getReferralList() {
        return await Vue.axios
            .get('referral/list_registers_by_referral')
            .then(res => res)
            .catch((err) => err.response);
    },
    async getReferralLink() {
        return await Vue.axios
            .post('referral/find_or_create')
            .then(res => res)
            .catch((err) => err.response);
    },
    async getReferralOpenModal() {
        return await Vue.axios
            .get('referral/show_modal')
            .then(res => res)
            .catch((err) => err.response);
    },
    async setReferralOpenModal() {
        return await Vue.axios
            .post('referral/create_or_update_closed_modal_event')
            .then(res => res)
            .catch((err) => err.response);
    },
    async sendIncompatibleMobileEmail() {
        return await Vue.axios
            .post('user/accessed_by_phone')
            .then(res => res)
            .catch((err) => err.response);
    },
    async addCreateListAutomaticEvent() {
        return await Vue.axios
            .post('user/add_create_list_automatic_event')
            .then(res => res)
            .catch((err) => err.response);
    },
    async getCreateListAutomaticEvent() {
        return await Vue.axios
            .get('user/get_create_list_automatic_event')
            .then(res => res)
            .catch((err) => err.response);
    },
    async getSpmBannerToUser() {
        return await Vue.axios
            .get(EndPoint.USER_AVAILABLE_FOR_SPM_PROMO)
            .then(res => res)
            .catch((err) => err.response);
    },
    async updateSpmBannerToUser() {
        return await Vue.axios
            .post(EndPoint.USER_UPDATE_STATUS_SPEEDMAIL_MODAL_PROMO)
            .then(res => res)
            .catch((err) => err.response);
    },
    async suggestSpeedmail() {
        return await Vue.axios
            .post(EndPoint.SUGGEST_SPEEDMAIL)
            .then(res => res)
            .catch((err) => err.response);
    },
    async getShowHyperbolic() {
        return await Vue.axios
            .get(EndPoint.SHOW_MODAL_EXPERIENCE_HYPERBOLIC)
            .then(res => res)
            .catch((err) => err.response);
    },
    async experienceUserHyperbolic(data) {
        return await Vue.axios
            .post(EndPoint.EXPERIENCE_USER_HYPERBOLIC, data)
            .then(res => res)
            .catch((err) => err.response);
    },
    async tryToRegisterNewAdditionalUser() {
        return await Vue.axios
            .post(EndPoint.TRIED_TO_REGISTER_NEW_ADDITIONAL_USER)
            .then(res => res)
            .catch((err) => err.response);
    },
    async tryToImportWithoutCredits() {
        return await Vue.axios
            .post(EndPoint.TRIED_TO_IMPORT_WITHOUT_CREDITS)
            .then(res => res)
            .catch((err) => err.response);
    },
    async enterSmConfig() {
        return await Vue.axios
            .post(EndPoint.ENTER_SM_CONFIG)
            .then(res => res)
            .catch((err) => err.response);
    },
    async clickedOnUpgrade() {
        return await Vue.axios
            .post(EndPoint.CLICKED_ON_UPGRADE)
            .then(res => res)
            .catch((err) => err.response);
    },
    async scheduledDemo() {
        return await Vue.axios
            .post(EndPoint.SCHEDULE_DEMO)
            .then(res => res)
            .catch((err) => err.response);
    },
    async contactSupport() {
        return await Vue.axios
            .post(`${EndPoint.USER}/fast_upgrade_modal/contact_support`)
            .then(res => res)
            .catch((err) => err.response);
    },
    async displayWelcomeScreen() {
        return await Vue.axios
            .get(EndPoint.DISPLAY_WELCOME_SCREEN)
            .then(res => res)
            .catch((err) => err.response);
    },
    async welcomeScreenSearch(data) {
        return await Vue.axios.post(EndPoint.WELCOME_SCREEN_SEARCH, data);
    },
    async toggleAutoplayActive(data) {
        return await Vue.axios
            .patch(
        //@ts-ignore
        `/history_search/${data.history_search_id}/set_autoplay_activeness`, {
            //@ts-ignore
            value: data.value,
        })
            .then(res => res)
            .catch((err) => err.response);
    },
    async saveAutoplay(data) {
        return await Vue.axios
            .post(
        //@ts-ignore
        `history_search/${data.history_search_id}/create_autoplay`, data)
            .then(res => res)
            .catch((err) => err.response);
    },
    async disableAllAutoplay() {
        return await Vue.axios
            .patch('user/autoplay/disable_all')
            .then(res => res)
            .catch((err) => err.response);
    },
    async getAutoplay(id) {
        return await Vue.axios
            .get(`user/autoplay/${id}`)
            .then(res => res)
            .catch((err) => err.response);
    },
    async saveCrmAutoplay(crm, token) {
        return await Vue.axios
            .post(
        //@ts-ignore
        EndPoint.AUTO_PLAY_CRMTOKEN, { crm: crm, value: token })
            .then(res => res)
            .catch((err) => err.response);
    },
    async getCrmAutoplay(crm) {
        return await Vue.axios
            .get(`user/${crm}/token`)
            .then(res => res)
            .catch((err) => err.response);
    },
    async getInfoAutoPlay() {
        return await Vue.axios
            .get('user/autoplay/credits_used')
            .then(res => res)
            .catch((err) => err.response);
    },
    async getAnswersWelcomeScreen() {
        return await Vue.axios
            .get('user/welcome_screen_answers')
            .then(res => res)
            .catch((err) => err.response);
    },
    async getDefaultSearch() {
        return await Vue.axios
            .get('user/default_search')
            .then(res => res)
            .catch((err) => err.response);
    },
    async updateDefaultSearch(data) {
        return await Vue.axios
            .patch('user/update_default_search', {
            default_search: data.defaultSearch,
            use_default_search: data.use_default_search,
        })
            .then(res => res)
            .catch((err) => err.response);
    },
    async getShowAvaliableAutoplay() {
        return Vue.axios
            .get(`${EndPoint.SHOW_AUTOPLAY}`)
            .then(res => res)
            .catch(err => err);
    },
    async createNewNote(data) {
        return Vue.axios
            .post(`${EndPoint.USER_NOTES}`, data)
            .then(res => res)
            .catch(err => err);
    },
    async deletNote(id) {
        return Vue.axios
            .delete(`${EndPoint.USER_NOTES}/${id}`)
            .then(res => res)
            .catch(err => err);
    },
    async getAllNotes(companyId) {
        return Vue.axios
            .get(`${EndPoint.USER_NOTES}/${companyId}`)
            .then(res => res)
            .catch(err => err);
    },
    async closedWelcomeBackModal() {
        return Vue.axios
            .patch('user/reactivation/saw_modal')
            .then(res => res)
            .catch(err => err);
    },
};
export const storage = {
    getUsers() {
        return UsersStore.keys().then(function (keys) {
            return Promise.all(keys.map(function (key) {
                return UsersStore.getItem(key).then(function (value) {
                    return { key: key, value: value };
                });
            }));
        });
    },
};
