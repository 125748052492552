import { QualityDataScore, } from '@/interfaces/search.interfaces';
export var SearchStatus;
(function (SearchStatus) {
    SearchStatus["RECEIVED"] = "received";
    SearchStatus["NOT_RECEIVED"] = "not_receive";
    SearchStatus["RECEIVING"] = "receiving";
    SearchStatus["NOT_FOUND"] = "not_found";
    SearchStatus["IDLE"] = "idle";
})(SearchStatus || (SearchStatus = {}));
export var CompanyStatus;
(function (CompanyStatus) {
    CompanyStatus["FETCH_SUCCESS"] = "fetch_success";
    CompanyStatus["FETCH_ERROR"] = "fetch_error";
    CompanyStatus["FETCHING"] = "fetching";
    CompanyStatus["INITIAL"] = "initial";
})(CompanyStatus || (CompanyStatus = {}));
export var SearchHistoryStatus;
(function (SearchHistoryStatus) {
    SearchHistoryStatus["CREATED"] = "created";
    SearchHistoryStatus["NOT_FOUND"] = "not_found";
    SearchHistoryStatus["ERROR"] = "error";
    SearchHistoryStatus["DELETED"] = "deleted";
    SearchHistoryStatus["IDLE"] = "idle";
})(SearchHistoryStatus || (SearchHistoryStatus = {}));
export var ExportType;
(function (ExportType) {
    ExportType["SINGLE"] = "single";
    ExportType["NORMAL"] = "normal";
    ExportType["SELECT"] = "select";
})(ExportType || (ExportType = {}));
export var ActiveModal;
(function (ActiveModal) {
    ActiveModal["updateAccountDataModal"] = "update_account_data_modal";
    ActiveModal["createAutomaticListModal"] = "create_automatic_list_modal";
    ActiveModal["advancedFilterModal"] = "advanced_filter_modal";
    ActiveModal["idle"] = "idle";
})(ActiveModal || (ActiveModal = {}));
export const state = {
    creditsBasic: null,
    creditsAdvanced: null,
    showCompany: false,
    showHistory: false,
    currentCompany: {},
    emptyReturn: false,
    infosetId: '',
    companies: [],
    graphics: {
        annualBilling: [],
        annualCompanyBilling: [],
        economicActivity: [],
        numberOfEmployees: [],
        numberOfCompanyEmployees: [],
        sectors: [],
        state: [{}],
        taxationModality: [{}],
    },
    advancedGraphics: {
        cloundProvider: [],
        companyPractices: [],
        decisionMakingDepartment: [],
        emphasesOfCompanies: [],
        formationOfDecisionMakers: [],
        interestsOfDecisionMakers: [],
        skillOfDecisionMakers: [],
        useOfTechnology: [],
        totalDecisionMakers: '',
        withEmails: '',
        withPhones: '',
    },
    summary: {
        companiesStatus: 0,
        totalEmployees: 0,
        totalResult: 0,
        totalRevenue: '',
    },
    page: 1,
    totalPage: 1,
    query: {
        search: {
            match: {},
            match_either: [],
            offset: 0,
            return_size: 10,
            where: {},
            wherenot: {},
            sort_by: {
                dt_abertura_formatted: '',
                revenue_a32_company_code: '',
                revenue_a32_cnpj_code: '',
                total_employees_r2016_company: '',
                _score: '',
                quality_data_score: QualityDataScore.DESC,
            },
        },
        special_filter: false,
        eliminate_cnpjs_already_exported_in_account: false,
    },
    currentTab: '',
    currentSearchId: '',
    currentCompanyCNPJ: '',
    additionalDataState: SearchStatus.NOT_RECEIVED,
    createSearchState: SearchStatus.NOT_RECEIVED,
    searchHistoryStatus: SearchHistoryStatus.IDLE,
    exportState: SearchStatus.NOT_RECEIVED,
    radioButtonState: SearchStatus.IDLE,
    resultCalcute: 0,
    loadingPotentialResults: false,
    exportCredits: {
        value: 0,
        remaining: 0,
    },
    noCredits: false,
    silentWarningCredits: false,
    stateModal: false,
    returnSize: 0,
    exportType: ExportType.NORMAL,
    searchByInteraction: false,
    histories: [],
    noCreditsToGetCompany: false,
    companiesToExport: [],
    companiesToExportDerived: state => state.companiesToExport,
    loadingFilter: false,
    showAdvancedFilterModal: false,
    companyStatus: CompanyStatus.INITIAL,
    exportedCompanies: 0,
    searchFromHistory: false,
    canSearch: true,
    isConsultHistory: false,
    activeModal: '',
    showCreateAutomaticExportList: false,
    showCreateAutomaticExportListDerived: state => state.showCreateAutomaticExportList,
    showFastPlanUpgrade: false,
    updateDataPopUp: {
        doneFirstSearch: false,
        showModal: false,
    },
    createAutomaticEventExist: false,
    showAutoPlayButton: false,
    defaultSearch: {
        active: false,
        id: '',
    },
    showMakeDecision: true,
};
