import { QualityDataScore, } from '@/interfaces/search.interfaces';
import { ActiveModal, CompanyStatus, ExportType, SearchStatus, } from '@/overmind/search/state';
import { formatDate, isAxiosResponse, parseRevenueValues, validPeriodToShowModal, } from '@/shared/utils/helpers';
import { SearchHistoryStatus } from '@/overmind/search/state';
import { isEmpty } from 'lodash';
import { json } from 'overmind';
import Vue from 'vue';
import * as exportsActions from './Exports';
import * as searchesActions from './Searches';
import * as searchHistories from './Histories';
import { handlerError } from './Searches/ErrorHandler';
export const searches = searchesActions;
export const exportations = exportsActions;
export const searchHistory = searchHistories;
export const getCompanySearch = async ({ actions: { search: actions }, effects: { companies: effects } }, cnpjs) => {
    actions.setNoCreditsToGetCompany(false);
    actions.setCompanyStatus(CompanyStatus.FETCHING);
    if (!isEmpty(cnpjs)) {
        let result = await effects.api.getCompanyByCnpj(cnpjs);
        if (result.status === 200 && result.data) {
            actions.setCompanyStatus(CompanyStatus.FETCH_SUCCESS);
            actions.setCurrentCompany(result.data);
        }
        else if (result.status === 500) {
            actions.setCompanyStatus(CompanyStatus.FETCH_ERROR);
            actions.setSearchState(SearchStatus.NOT_RECEIVED);
        }
        else if (result.status === 401) {
            // @ts-ignore TS2349: property inexistent
            Vue.auth.logout();
        }
        else if (result.status === 422) {
            actions.setCompanyStatus(CompanyStatus.FETCH_ERROR);
            if (result.data.error === 'Crédito não disponível') {
                actions.setNoCreditsToGetCompany(true);
                actions.setAdditionalDataState('noCreditsAvailable');
            }
        }
        result = null;
    }
    return;
};
export const getCompanyAdvancedData = async ({ state: { search: state }, actions: { search: actions }, effects: { search: effects }, }) => {
    //@ts-ignore
    if (state.additionalDataState === SearchStatus.NOT_RECEIVED) {
        actions.setAdditionalDataState(SearchStatus.RECEIVING);
        if (state.currentCompanyCNPJ) {
            const result = await effects.api.getCompanyAdvanced(state.currentCompanyCNPJ);
            if (result.status === 200) {
                actions.setAdditionalDataState(SearchStatus.RECEIVED);
                if (result.data.data.attributes) {
                    const additionalData = result.data.data.attributes
                        .additional_data_atlas
                        ? result.data.data.attributes.additional_data_atlas.map(item => Object.values(item)[0])
                        : undefined;
                    const administrationData = result.data.data.attributes.administration_data;
                    const website = result.data.data.attributes.website_information;
                    state.currentCompany = {
                        ...state.currentCompany,
                        additionalData,
                        administrationData,
                        website,
                    };
                    state.currentCompany = json(state.currentCompany);
                }
            }
            else if (result.status === 401) {
                // @ts-ignore TS2349: property inexistent
                Vue.auth.logout();
            }
        }
    }
};
export const getCredits = async ({ effects: { search: effects }, actions: { search: actions }, }) => {
    const result = await effects.api.getCredits();
    if (!isEmpty(result.data) && result.status === 200) {
        let credits = {
            value: 0,
            remaining: 0,
        };
        const exports = result.data.credits.filter(credit => credit.type === 'export');
        credits = exports.reduce((acc, curr) => {
            acc.value = acc.value + curr.value;
            acc.remaining = acc.remaining + curr.remaining;
            return acc;
        }, credits);
        actions.setExportCredits(credits);
    }
    else if (result.status === 401) {
        // @ts-ignore TS2349: property inexistent
        Vue.auth.logout();
    }
};
export const exportCompanies = async ({ effects: { search: effects }, actions: { search: actions, infosets: infosetsActions }, }, { searchId, cnpjs, infosetId }) => {
    const result = await effects.api.exportCompanies({
        search_id: searchId,
        cnpjs: cnpjs,
        info_set_id: infosetId,
    });
    actions.setInfoseId(infosetId);
    if (!isEmpty(result)) {
        if (result.status === 201 || result.status === 200) {
            if (infosetId) {
                await infosetsActions.fetchInfosetByID(infosetId);
            }
            actions.statusTransitionExport(SearchStatus.RECEIVED);
            return result;
        }
        else if (result.status === 500) {
            actions.statusTransitionExport(SearchStatus.IDLE);
        }
        else if (result.status === 401) {
            // @ts-ignore TS2349: property inexistent
            Vue.auth.logout();
        }
    }
};
// CONTEXTO FILTROS
export const setInputFilters = ({ actions: { search: actions } }, inputFilters) => {
    if (!isEmpty(inputFilters.no_municipio_exterior.value)) {
        actions.setSearchNoMunicipioExterior(inputFilters.no_municipio_exterior.value);
    }
    if (!isEmpty(inputFilters['additional_data_atlas.data_gpt.product_service'].value)) {
        actions.setGPTKeyWord(inputFilters['additional_data_atlas.data_gpt.product_service'].value);
    }
    if (!isEmpty(inputFilters.no_logradouro.value)) {
        actions.setSearchNoLogradouro(inputFilters.no_logradouro.value);
    }
    if (!isEmpty(inputFilters['match_phrase.additional_data_consolidated.full_qsa.data_format_structured.field_value@name_partners'].value)) {
        actions.setSearchSocioName(inputFilters['match_phrase.additional_data_consolidated.full_qsa.data_format_structured.field_value@name_partners'].value);
    }
    if (!isEmpty(inputFilters.nu_cep.value)) {
        actions.setSearchNuCep(inputFilters.nu_cep.value);
    }
};
// CONTEXTO FILTROS
export const setSliderFilters = ({ actions: { search: actions } }, sliderFilters) => {
    actions.setSearchFaturamento(json(sliderFilters.revenues.value));
    actions.setSearchFuncionarios(json(sliderFilters.employees.value));
    actions.setSearchDistance();
};
// CONTEXTO FILTROS
export const setRangeNuCapitalSocial = ({ state: { search: state, filters: stateFilters }, }) => {
    const valueStart = stateFilters.selectedInputRange.nu_capital_socialnumber_integer.value_start;
    const valueEnd = stateFilters.selectedInputRange.nu_capital_socialnumber_integer.value_end;
    if (valueStart !== null && valueEnd !== null) {
        state.query.search.where['nu_capital_socialnumber_integer'] = [
            valueStart,
            valueEnd,
        ];
    }
};
// CONTEXTO FILTROS
export const setRangeNuFiliais = ({ state: { search: state, filters: stateFilters }, }) => {
    const valueStart = stateFilters.selectedInputRange.nu_filiais.value_start;
    const valueEnd = stateFilters.selectedInputRange.nu_filiais.value_end;
    if (valueStart !== null && valueEnd !== null) {
        state.query.search.where['nu_filiais'] = [valueStart, valueEnd];
    }
};
export const setInputRangeFilter = ({ state: { search: state, filters: stateFilters } }, { queryType }) => {
    const valueStart = stateFilters.selectedInputRange[queryType].value_start;
    const valueEnd = stateFilters.selectedInputRange[queryType].value_end;
    if (valueStart !== null && valueEnd !== null) {
        if (stateFilters.selectedRadioButton.employees)
            state.query.search.where[queryType] = [valueStart, valueEnd];
    }
};
export const setRangeReclameAquiNuPercentageOfComplaintsResolved = ({ state: { search: state, filters: stateFilters }, }) => {
    const valueStart = stateFilters.selectedInputRange['reclamacoes_reclame_aqui.solvedPercentual']
        .value_start;
    const valueEnd = stateFilters.selectedInputRange['reclamacoes_reclame_aqui.solvedPercentual']
        .value_end;
    if (valueStart !== null && valueEnd !== null) {
        state.query.search.where['reclamacoes_reclame_aqui.solvedPercentual'] = [
            valueStart,
            valueEnd,
        ];
    }
};
export const setRangeReclameAquiNuComplaintScore = ({ state: { search: state, filters: stateFilters }, }) => {
    const valueStart = stateFilters.selectedInputRange['reclamacoes_reclame_aqui.finalScore']
        .value_start;
    const valueEnd = stateFilters.selectedInputRange['reclamacoes_reclame_aqui.finalScore']
        .value_end;
    if (valueStart !== null && valueEnd !== null) {
        state.query.search.where['reclamacoes_reclame_aqui.finalScore'] = [
            valueStart,
            valueEnd,
        ];
    }
};
export const setInputRangeTotalEmployees = ({ state: { search: state, filters: stateFilters }, }) => {
    let valueStart;
    let valueEnd;
    const queryField = json(stateFilters.selectedRadioButton.employees.queryField);
    const inputValues = json(stateFilters.selectedInputRange[stateFilters.selectedRadioButton.employees.queryField]);
    if (inputValues.value_start === null && inputValues.value_end === null) {
        if (queryField === 'total_employees_r2016_company') {
            stateFilters.selectedInputRange[queryField] = json(stateFilters.selectedInputRange.total_employees_r2016_cnpj);
            stateFilters.selectedInputRange.total_employees_r2016_cnpj = json({
                value_start: null,
                value_end: null,
            });
        }
        else {
            if (queryField === 'total_employees_r2016_cnpj') {
                stateFilters.selectedInputRange[queryField] = json(stateFilters.selectedInputRange.total_employees_r2016_company);
            }
            stateFilters.selectedInputRange.total_employees_r2016_company = json({
                value_start: null,
                value_end: null,
            });
        }
    }
    if (!isEmpty(stateFilters.selectedInputRange[stateFilters.selectedRadioButton.employees.queryField])) {
        valueStart =
            stateFilters.selectedInputRange[stateFilters.selectedRadioButton.employees.queryField].value_start;
        valueEnd =
            stateFilters.selectedInputRange[stateFilters.selectedRadioButton.employees.queryField].value_end;
    }
    if (valueStart !== null && valueEnd !== null) {
        state.query.search.where[stateFilters.selectedRadioButton.employees.queryField] = [valueStart.toString(), valueEnd.toString()];
        if (stateFilters.selectedRadioButton.employees.queryField ===
            'total_employees_r2016_company') {
            delete state.query.search.where['total_employees_r2016_cnpj'];
        }
        else {
            delete state.query.search.where['total_employees_r2016_company'];
        }
    }
};
export const setReturnSize = ({ state: { search: state } }, value) => {
    state.returnSize = value;
};
export const showAdvancedFilterModal = ({ state: { search: state }, state: { modals: stateModals }, actions }, { show, plgRole = false, searchId, clickedBtn = false }) => {
    if (stateModals.currentActiveModal != '')
        return;
    //@ts-ignore
    const hasSearchId = !!searchId;
    //@ts-ignore
    const userPlg = !actions.users.getAuthPermissions(true);
    //@ts-ignore
    const noAutoShowModalForPlgUser = !userPlg && plgRole;
    if (show) {
        // Se o usuário fez busca ou não é plg e carrega a página do search então não mostra
        if ((hasSearchId && !clickedBtn) || noAutoShowModalForPlgUser) {
            state.activeModal = '';
            state.showAdvancedFilterModal = false;
        }
        else {
            actions.search.handleActiveModal({
                modal: ActiveModal.advancedFilterModal,
            });
            state.showAdvancedFilterModal = true;
        }
        return;
    }
    else {
        if (state.activeModal !== '' &&
            // @ts-ignore
            state.activeModal === ActiveModal.advancedFilterModal)
            state.activeModal = '';
        state.showAdvancedFilterModal = false;
    }
};
// CONTEXTO FILTROS
export const setFilterOracle = ({ state: { search: state } }, value) => {
    state.query.search.match['client_flags_array'] = json(value);
};
export const setFilterNeighboorhoods = ({ state: { search: state } }, value) => {
    state.query.search.match['no_bairro'] = json(value);
};
export const setFilterCeps = ({ state: { search: state } }, value) => {
    state.query.search.match['nu_cep'] = json(value);
};
// CONTEXTO FILTROS
export const setRemoveFilterOracle = ({ state: { search: state } }, value) => {
    state.query.search.wherenot['client_flags_array@where_not'] = json(value);
};
export const setWhereNotNoEmpresa = ({ state: { search: state } }, value) => {
    state.query.search.wherenot['no_empresa@where_not'] = json(value);
};
export const setInfoseId = ({ state: { search: state } }, value) => {
    state.infosetId = value;
};
export const setRadioButton = ({ actions: { search: actions } }) => {
    actions.setCnaeRadioButton();
    actions.setEmployeesRadioButton();
    actions.setRevenuesRadioButton();
};
export const setExportCredits = ({ state: { search: state } }, credits) => {
    state.exportCredits = credits;
};
export const setSilentWarningCredits = ({ state: { search: state } }, value) => {
    state.silentWarningCredits = value;
};
export const setShowHistory = ({ state: { search: state } }, value) => {
    state.showHistory = value;
};
export const toggleShowHistory = ({ state: { search: state }, }) => {
    state.showHistory = !state.showHistory;
};
// CONTEXTO FILTROS
export const setSortBy = ({ state: { search: state, filters: stateFilters }, }) => {
    const selectedSort = json(stateFilters.selectedSort);
    if (selectedSort && selectedSort.length) {
        selectedSort.forEach(filter => {
            if (filter.queryField === 'quality_data_score')
                state.query.search.sort_by.quality_data_score = QualityDataScore.DESC;
            if (filter.queryField === 'dt_abertura_formatted') {
                state.query.search.sort_by.dt_abertura_formatted = filter.value;
            }
            if (filter.queryField === 'revenue_a32_company_code') {
                state.query.search.sort_by.revenue_a32_company_code = filter.value;
            }
            if (filter.queryField === 'revenue_a32_cnpj_code') {
                state.query.search.sort_by.revenue_a32_cnpj_code = filter.value;
            }
            if (filter.queryField === 'total_employees_r2016_company') {
                state.query.search.sort_by.total_employees_r2016_company = filter.value;
            }
            if (filter.queryField === '_score') {
                state.query.search.sort_by._score = filter.value;
            }
        });
    }
    // TODO: Rethink a better way to populate sort_by dynamically
    /*if (state.query.search.sort_by) {
      state.query.search.sort_by[param.key!] = param.value;
    } else {
      state.query.search = {
        ...state.query.search,
        sort_by: {
          [param.key!]: param.value
        }
      };
    }*/
};
// CONTEXTO FILTROS
export const setDateFilters = ({ state: { search: state } }, value) => {
    Object.keys(json(value)).forEach(key => {
        if (!isEmpty(value[key].inDate) && !isEmpty(value[key].toDate)) {
            const inDate = value[key].inDate.indexOf('-') > -1
                ? value[key].inDate
                : formatDate(value[key].inDate);
            const toDate = value[key].toDate.indexOf('-') > -1
                ? value[key].toDate
                : formatDate(value[key].toDate);
            state.query.search.where[key] = [`${inDate}`, `${toDate}`];
        }
        else {
            // TODO: Código comentado fora! Verificar o porquê desta linha de código
            // parece ser algo sem necessidade ou que pode levar a bugs!!!
            //state.query.search.where[key] = '';
        }
    });
};
// This function marks an intention to create search
// resulting a interaction from the user in the UI
// This helpers to differentiate when making automatic
// searches by URL id, so we avoid to duplicate calls to
// the server
export const setSearchByInteraction = ({ state: { search: state } }, value) => {
    state.searchByInteraction = value;
};
export const setEmployeesRadioButton = ({ state: { filters: state }, }) => {
    state.stateSliderFilters.employees.queryField =
        state.selectedRadioButton.employees.queryField;
};
// CONTEXTO FILTROS
export const setRevenuesRadioButton = ({ state: { filters: state }, }) => {
    state.stateSliderFilters.revenues.queryField =
        state.selectedRadioButton.revenues.queryField;
};
// CONTEXTO FILTROS
export const setCnaeRadioButton = ({ state: { filters: state }, actions: { search: actions }, }) => {
    const index = state.selectedCheckbox.findIndex(item => item.queryField === 'cnae1' ||
        item.queryField === 'cnae2' ||
        item.queryField === 'cnae_all' ||
        item.queryField === 'smart_cnae1');
    if (index !== -1) {
        state.selectedCheckbox[index].queryField =
            state.selectedRadioButton.cnae.queryField;
    }
    actions.setRadioButtonState(SearchStatus.RECEIVED);
    setTimeout(function () {
        actions.setRadioButtonState(SearchStatus.IDLE);
    }, 1000);
};
// CONTEXTO FILTROS
export const setRadioButtonState = ({ state: { search: state } }, value) => {
    state.radioButtonState = json(value);
};
export const createSimilarSearch = async ({ state, effects: { search: effects }, actions: { search: actionsSearch, filters: actionsFilters }, }) => {
    state.search.loadingFilter = true;
    const sessionStorageCnpjs = sessionStorage.getItem('filters_similar_cnpj');
    if (sessionStorageCnpjs !== null) {
        const result = await effects.api.createSimilarSearch(JSON.parse(sessionStorageCnpjs));
        if (result.status === 201) {
            actionsSearch.setCurrentSearchId(result.data.data.id);
            if (result.data && result.data.data && result.data.data.id) {
                actionsSearch.searches.getSearch({ id: result.data.data.id });
                state.search.loadingFilter = false;
            }
        }
        else {
            if (result.status && typeof result.status === 'number') {
                handlerError({ search: actionsSearch, filters: actionsFilters }, result.status);
            }
        }
    }
};
export const setSearchRemoveCompanies = ({ state: { filters: stateFilters, search: state }, }) => {
    const value = stateFilters.exclusiveOptions.find(item => item.value === 'eliminate_cnpjs_already_exported_in_account');
    if (value && value.isSelected === true) {
        state.query.eliminate_cnpjs_already_exported_in_account = true;
    }
    else {
        state.query.eliminate_cnpjs_already_exported_in_account = false;
    }
};
export const setSearchExists = ({ state: { search: state } }, value) => {
    state.query.search.exists = json(value);
};
export const setSearchDistance = ({ state: { search: state, filters: stateFilters }, }) => {
    state.query.search.where[stateFilters.stateSliderFilters.cep_distance.queryField || 'cep_distance'] = {
        distance: stateFilters.stateSliderFilters.cep_distance.distance,
        cep: stateFilters.stateSliderFilters.cep_distance.zip,
    };
};
export const setSearchFuncionarios = ({ state: { search: state, filters: stateFilters }, actions: { search: actions }, }, value) => {
    state.query.search.where[stateFilters.selectedRadioButton.employees.queryField] = actions.formatArraySliders({
        value: value,
        from: 'funcionarios',
    });
};
export const setSearchFaturamento = ({ state: { search: state, filters: stateFilters }, actions: { search: actions }, }, value) => {
    state.query.search.where[stateFilters.selectedRadioButton.revenues.queryField] = actions.formatArraySliders({
        value: value,
        from: 'faturamento',
    });
};
export const setDefaultActiveCompanies = ({ state: { search: state } }, value) => {
    const newValue = value.find(item => item === 'ds_situacao_cnpj');
    if (newValue) {
        if (state.query.search.match[newValue]) {
            state.query.search.match[newValue].push('ATIVA');
        }
        else {
            state.query.search.match[newValue] = ['ATIVA'];
        }
    }
};
export const setSearchHasAny = ({ state: { search: state } }, values) => {
    values.forEach(value => {
        value === 'emails'
            ? (state.query.search.match['has_any'] = [value])
            : state.query.search.match_either.push(value);
    });
};
export const setSearchNuCnpjs = ({ state: { search: state } }, value) => {
    state.query.search.match['nu_cnpj'] = json(value);
};
export const setGPTKeyWord = ({ state: { search: state } }, value) => {
    state.query.search.match['additional_data_atlas.data_gpt.product_service'] =
        json(value);
};
export const setPositionCombinatins = ({ state: { search: state } }, value) => {
    const select = json(value);
    state.query.search.match['m5_position_combinations'].push(select);
};
export const setWhereNotNuCnpjs = ({ state: { search: state } }, value) => {
    state.query.search.wherenot['nu_cnpj@where_not'] = json(value);
};
export const setSearchNoMunicipioExterior = ({ state: { search: state } }, value) => {
    state.query.search.match['no_municipio_exterior'] = value;
};
export const setSearchSocioName = ({ state: { search: state } }, value) => {
    state.query.search.match['match_phrase.additional_data_consolidated.full_qsa.data_format_structured.field_value@name_partners'] = value;
};
export const setSearchNoLogradouro = ({ state: { search: state } }, value) => {
    state.query.search.match['no_logradouro'] = value;
};
export const setSearchNuCep = ({ state: { search: state } }, value) => {
    state.query.search.match['nu_cep'] = value.replace(/[^a-z0-9]/gi, '');
};
export const setSearchAggregationType = ({ state: { search: state } }, value) => {
    state.query.search.aggregation_type = 'advanced';
};
export const setSearchReturnSize = ({ state: { search: state } }, value) => {
    state.query.search.return_size = value;
};
export const setSelectedFilteredType = ({ state }, value) => {
    state.search.query['selected_filtered_type'] = value;
    state.filters.selectedFilteredType = value;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setSearchCheckbox = ({ state: { search: state }, actions: { search: actions } }, value) => {
    // NOTE Country filters sending UPPERCASE
    Object.keys(value).forEach(item => {
        if (item === 'no_pais') {
            value[item] = value[item].map(selectedCheckbox => selectedCheckbox.value.toUpperCase());
        }
        else {
            value[item] = value[item].map(selectedCheckbox => selectedCheckbox.value);
        }
    });
    // NOTE Validating MatchEither
    actions.validateMatchEither(value);
    // NOTE Remove filters for matchEither
    Object.keys(value).forEach(item => {
        if (item === 'cnae1' ||
            item === 'cnae2' ||
            item === 'sg_uf' ||
            item === 'cnae_all' ||
            item === 'smart_cnae1' ||
            item === 'main_segment' ||
            item === 'co_municipio_ibge' ||
            item === '_multiple_municipios_' ||
            item === '_multiple_estados_' ||
            item === 'main_cnae_group_code') {
            delete value[item];
        }
    });
    state.query.search.match = value;
};
export const setSearchWhereNot = ({ state: { search: state } }, value) => {
    Object.keys(value).forEach(item => {
        if (Array.isArray(value[item])) {
            value[item] = value[item].map(option => {
                return option && option.value !== undefined ? option.value : option;
            });
        }
    });
    state.query.search.wherenot = value;
};
export const setSearchOffset = ({ state: { search: state } }, value) => {
    state.query.search.offset = value;
};
export const setAdditionalDataState = ({ state: { search: state } }, value) => {
    state.additionalDataState = value;
};
export const setCurrentSearchId = ({ state: { search: state } }, value) => {
    state.currentSearchId = value;
};
export const setPotentialResults = ({ state: { search: state } }, result) => {
    state.resultCalcute = result || 0;
};
export const setPageNumber = ({ state: { search: state } }, value) => {
    state.page = value;
};
export const loadingPotentialResults = ({ state: { search: state } }, value) => {
    state.loadingPotentialResults = value;
};
export const setTotalPage = ({ state: { search: state } }, value) => {
    state.totalPage = Math.ceil(value / 10);
};
export const setCompanies = ({ state: { search: state } }, value) => {
    state.companies = value;
};
export const setCNPJCurrentCompany = ({ state: { search: state } }, value) => {
    state.currentCompanyCNPJ = value;
    if (!state.companiesToExport.includes(value))
        state.companiesToExport.push(value);
};
export const setEmptyReturn = ({ state: { search: state } }, value) => {
    state.emptyReturn = value;
};
export const setNoCredits = ({ state: { search: state } }, value) => {
    state.noCredits = value;
};
export const setNoCreditsToGetCompany = ({ state: { search: state } }, value) => {
    state.noCreditsToGetCompany = value;
};
export const setShowCompany = ({ state: { search: state } }, value) => {
    state.showCompany = value;
};
export const setCurrentCompany = ({ state: { search: state, companies: companiesState } }, value) => {
    state.currentCompany = json(value);
    companiesState.currentCompany = json(value);
};
export const statusTransitionExport = ({ state: { search: state } }, value) => {
    state.exportState = value;
};
export const resetTimeout = ({ state: { filters: state } }) => {
    state.timeoutID = null;
};
export const clearTimeout = ({ state: { filters: state } }) => {
    if (typeof state.timeoutID === 'number') {
        window.clearTimeout(state.timeoutID);
    }
};
export const resetCurrentCompany = ({ state: { search: state }, actions: { search: actions }, }) => {
    state.currentCompanyCNPJ = '';
    state.currentCompany = undefined;
    state.additionalDataState = SearchStatus.NOT_RECEIVED;
    actions.setCompanyStatus(CompanyStatus.INITIAL);
};
export const resetCompaniesSelectedToImport = ({ state: { search: state }, actions: { search: actions }, }) => {
    state.companiesToExport = [];
};
export const setAdvancedDataState = ({ state: { search: state } }, value) => {
    state.additionalDataState = value;
};
export const setSearchState = ({ state: { search: state } }, value) => {
    state.createSearchState = value;
};
export const resetSearchQuery = async ({ state: { search: state }, }) => {
    state.query.search = {
        match_either: [],
        match: {},
        offset: 0,
        return_size: 10,
        where: {},
        wherenot: {},
        sort_by: {
            dt_abertura_formatted: '',
            revenue_a32_company_code: '',
            revenue_a32_cnpj_code: '',
            total_employees_r2016_company: '',
            _score: '',
            quality_data_score: QualityDataScore.DESC,
        },
    };
    state.query.eliminate_cnpjs_already_exported_in_account = false;
};
export const resetGraphics = ({ state: { search: state } }) => {
    state.graphics = {
        annualBilling: [],
        annualCompanyBilling: [],
        economicActivity: [],
        numberOfEmployees: [],
        numberOfCompanyEmployees: [],
        sectors: [],
        state: [{}],
        taxationModality: [{}],
    };
};
export const resetAdvancedGraphics = ({ state: { search: state } }) => {
    state.advancedGraphics = {
        cloundProvider: [],
        companyPractices: [],
        decisionMakingDepartment: [],
        emphasesOfCompanies: [],
        formationOfDecisionMakers: [],
        interestsOfDecisionMakers: [],
        skillOfDecisionMakers: [],
        totalDecisionMakers: '',
        useOfTechnology: [],
        withEmails: '',
        withPhones: '',
    };
};
export const toggleModalExport = ({ state: { search: state }, actions: { search: actions } }, { value, type }) => {
    if (!type)
        type = state.companiesToExport.length
            ? ExportType.SELECT
            : ExportType.NORMAL;
    if (type === ExportType.NORMAL)
        actions.setReturnSize(0);
    actions.setExportType(type);
    state.stateModal = value;
};
export const setExportType = ({ state: { search: state } }, value) => {
    state.exportType = value;
};
export const resetStateSearch = ({ state: { search: state }, actions: { search: actions }, }) => {
    actions.resetCurrentCompany();
    actions.setSearchState(SearchStatus.NOT_RECEIVED);
    actions.setAdvancedDataState(SearchStatus.NOT_RECEIVED);
    actions.statusTransitionExport(SearchStatus.IDLE);
    actions.resetGraphics();
    actions.resetAdvancedGraphics();
    actions.setCurrentSearchId('');
    actions.setEmptyReturn(false);
    actions.loadingPotentialResults(false);
    actions.setPageNumber(1);
    actions.resetSearchQuery();
    actions.setPotentialResults(0);
    actions.setCompanies([]);
    actions.setShowCompany(false);
    actions.resetTimeout();
    actions.setExportCredits({ value: 0, remaining: 0 });
    actions.toggleModalExport({ value: false, type: ExportType.NORMAL });
    //actions.setNoCredits(false);
    state.summary = undefined;
    state.totalPage = 1;
    state.searchByInteraction = false;
    state.showHistory = false;
    state.companiesToExport = [];
};
// TODO: renomear
export const getSelectedCheckbox = ({ state: { filters: state } }, { forExclusion }) => {
    const filterSelectedCheckbox = state.selectedCheckbox.filter(item => forExclusion ? item.groupTag === 'where_not' : item.groupTag !== 'where_not');
    const selectedCheckbox = filterSelectedCheckbox.reduce((obj, item) => ((obj[item.queryField] = item.filterOptions), obj), {});
    return json(selectedCheckbox);
};
// TODO: renomear
// TODO: refatorar em funções menores
export const getExclusiveSelectedOptions = ({ state: { filters: state } }) => {
    const selectedCheckbox = {
        exists: [],
        hasAny: [],
        query: [],
        removeCompanies: false,
    };
    state.exclusiveOptions
        .filter(item => item.isSelected === true)
        .forEach(value => {
        if (value.value === 'emails') {
            selectedCheckbox.hasAny.push(value.value);
        }
        if (value.value === 'cellphones' || value.value === 'phones') {
            selectedCheckbox.hasAny.push({ has_any: [value.value] });
        }
        if (value.value === 'website_data.url' ||
            value.value === 'decision_makers_lkd_m5' ||
            value.value === 'additional_data_atlas.lawsuits') {
            selectedCheckbox.exists.push(value.value);
        }
        if (value.value ===
            'administrator_data,additional_data_consolidated.full_qsa.title') {
            const handlerString = value.value.split(',');
            handlerString.forEach(item => {
                selectedCheckbox.exists.push(item.trim());
            });
        }
        if (value.value === 'ds_situacao_cnpj') {
            selectedCheckbox.query.push(value.value);
        }
    });
    return json(selectedCheckbox);
};
export const formatArraySliders = ({}, { value, from }) => {
    const newArray = [];
    if (from === 'faturamento') {
        if (Array.isArray(value)) {
            if (Number.parseInt(value[1]) !== 0)
                return parseRevenueValues(value);
        }
        return newArray;
    }
    else if (from === 'funcionarios') {
        return [
            value[0] === '+500' ? '1000000' : value[0].toString(),
            value[1] === '+500' ? '1000000' : value[1].toString(),
        ];
    }
    return [];
};
// TODO: refatorar em funções menores
export const validateMatchEither = ({ state: { search: state } }, value) => {
    const activity = {
        cnae1: [],
        cnae2: [],
        cnae_all: [],
        smart_cnae1: [],
        main_segment: [],
        main_cnae_group_code: [],
    };
    const localization = {
        co_municipio_ibge: [],
        sg_uf: [],
    };
    Object.keys(value).forEach(item => {
        switch (item) {
            case 'co_municipio_ibge':
                localization.co_municipio_ibge = value[item].map(item => item);
                break;
            case '_multiple_municipios_':
                localization.co_municipio_ibge = value[item].map(item => item);
                break;
            case 'main_cnae_group_code':
                activity.main_cnae_group_code = value[item].map(item => item);
                break;
            case 'main_segment':
                activity.main_segment = value[item].map(item => item);
                break;
            case 'smart_cnae1':
                activity.smart_cnae1 = value[item].map(item => item);
                break;
            case 'sg_uf':
                localization.sg_uf = value[item].map(item => item);
                break;
            case '_multiple_estados_':
                localization.sg_uf = value[item].map(item => item);
                break;
            case 'cnae_all':
                activity.cnae_all = value[item].map(item => item);
                break;
            case 'cnae1':
                activity.cnae1 = value[item].map(item => item);
                break;
            case 'cnae2':
                activity.cnae2 = value[item].map(item => item);
                break;
        }
    });
    Object.keys(activity).forEach(item => {
        if (isEmpty(activity[item])) {
            delete activity[item];
        }
    });
    Object.keys(localization).forEach(item => {
        if (isEmpty(localization[item])) {
            delete localization[item];
        }
    });
    if (!isEmpty(localization)) {
        state.query.search.match_either.push(localization);
    }
    if (!isEmpty(activity)) {
        state.query.search.match_either.push(activity);
    }
};
export const getSearchHistory = async ({ effects: { search: effects }, actions: { search: actions }, actions: { users: actionsUsers }, state: { search: state }, }) => {
    const result = await effects.api.getSearchHistories();
    if (result) {
        const results = result.data.data;
        //@ts-ignore
        if (results.length == 0 && actionsUsers.getAuthPermissions(true)) {
            state.showAutoPlayButton = false;
            actions.searchHistory.setSearchHistoryStatus(SearchHistoryStatus.NOT_FOUND);
            return;
        }
        //@ts-ignore
        if (actionsUsers.getAuthPermissions(true)) {
            state.showAutoPlayButton = true;
        }
        actions.buildSearchHistories(results);
    }
    return;
};
export const createSearchHistory = async ({ effects, actions: { search: actions }, state: { search: state } }, search) => {
    const oldHistories = json(state.histories);
    const newHistories = [...oldHistories, search];
    actions.setSearchHistories(newHistories);
    const result = await effects.search.api.createSearchHistory(search);
    if (result.data || result.data.status === 201) {
        actions.searchHistory.setSearchHistoryStatus(SearchHistoryStatus.CREATED);
        const histories = json(state.histories);
        histories[histories.length - 1].id = result.data.data.id;
        actions.setSearchHistories(histories);
        // TODO: Investigar se este setTimeout está quebrando os testes unitários
        // se sim, deverá ser retirado e testado se o comportamento continua correto na UI
        setTimeout(() => {
            actions.searchHistory.setSearchHistoryStatus(SearchHistoryStatus.IDLE);
        }, 2000);
    }
    else {
        actions.searchHistory.setSearchHistoryStatus(SearchHistoryStatus.ERROR);
        actions.setSearchHistories(oldHistories);
    }
    return;
};
export const deleteSearchHistory = async ({ effects: { search: effects }, actions: { search: actions }, state: { search: state }, }, id) => {
    const filterId = json(state.histories);
    const oldHistories = state.histories;
    const histories = filterId.filter(history => {
        if (history.id !== id)
            return history;
    });
    if (id) {
        const newHistories = [...histories];
        actions.setSearchHistories(newHistories);
        const result = await effects.api.removeSearchHistory(id);
        if (result) {
            actions.searchHistory.setSearchHistoryStatus(SearchHistoryStatus.DELETED);
        }
        else {
            actions.searchHistory.setSearchHistoryStatus(SearchHistoryStatus.ERROR);
            actions.setSearchHistories(oldHistories);
        }
    }
    // TODO: Investigar se este setTimeout está quebrando os testes unitários
    // se sim, deverá ser retirado e testado se o comportamento continua correto na UI
    setTimeout(() => {
        actions.searchHistory.setSearchHistoryStatus(SearchHistoryStatus.IDLE);
    }, 2000);
};
export const setSearchHistories = ({ state: { search: state } }, histories) => {
    state.histories = json(histories);
};
export const buildSearchHistories = ({ state: { search: state }, actions: { search: actions } }, results) => {
    actions.setSearchHistories(results.map(item => {
        return {
            id: item.id,
            searchId: item.attributes.search_id.$oid,
            name: item.attributes.name,
            totalResults: item.attributes.total_results,
            createdAt: item.attributes.created_at,
            autoplay: item.attributes.autoplay,
            autoplay_id: item.attributes.autoplay_id.$oid,
            selected_filters: item.attributes.selected_filters,
            totalDecisors: item.attributes.total_decisors,
        };
    }));
};
export const setCompanyStatus = ({ state: { search: state } }, status) => {
    state.companyStatus = status;
};
export const getExportedCompaniesCount = async ({ state: { search: state }, effects: { search: effects }, }) => {
    const result = await effects.api.getExportedCompaniesCount();
    if (isAxiosResponse(result)) {
        const exportedCount = result.data;
        state.exportedCompanies = exportedCount;
    }
};
export const setSearchFromHistory = ({ state: { search: state } }, value) => {
    state.searchFromHistory = value;
};
export const setCanSearch = async ({ state: { search: state } }, value) => {
    state.canSearch = value;
};
export const getCanSearch = ({ state: { search: state } }) => {
    return state.canSearch;
};
export const handleActiveModal = ({ state: { search: state } }, { modal }) => {
    const activeModal = state.activeModal;
    if (modal === '' ||
        (activeModal === ActiveModal.updateAccountDataModal &&
            !state.updateDataPopUp.showModal))
        state.activeModal = '';
    if (!activeModal)
        state.activeModal = modal;
};
export const openCreateAutomaticListModal = ({ state: { search: state }, state: { users: stateUsers }, state: { modals: stateModals }, actions, }) => {
    if (stateModals.currentActiveModal != '')
        return;
    const journey = stateUsers.userJourney;
    if (journey.is_new_account) {
        //@ts-ignore
        const haveCreateListAutomaticEvent = state.createAutomaticEventExist;
        //@ts-ignore
        const userPlg = !actions.users.getAuthPermissions(true);
        const hasCompanies = state.companies && state.companies.length >= 1;
        const haveFirstSearch = journey.user_journey.includes('primeira_busca_realizada');
        const validPeriodToShowCreateListAutomaticModal = validPeriodToShowModal('noShowCreateInfosetModal', 1);
        const allowedConsumedCredits = stateUsers.accountCredits.export.total -
            stateUsers.accountCredits.export.consumed >=
            10;
        if (userPlg &&
            haveFirstSearch &&
            hasCompanies &&
            validPeriodToShowCreateListAutomaticModal &&
            allowedConsumedCredits &&
            !haveCreateListAutomaticEvent) {
            actions.search.handleActiveModal({
                modal: ActiveModal.createAutomaticListModal,
            });
            if (state.activeModal === ActiveModal.createAutomaticListModal) {
                setTimeout(() => {
                    state.showCreateAutomaticExportList = true;
                    state.companiesToExport = state.companies.map(r => r.cnpj);
                }, 15000);
            }
        }
        else {
            if (state.activeModal !== '' &&
                state.activeModal === ActiveModal.createAutomaticListModal)
                state.activeModal = '';
            // state.companiesToExport = []
            state.showCreateAutomaticExportList = false;
        }
    }
};
export const openUpdateAccountDataModal = ({ state, actions }) => {
    if (state.modals.currentActiveModal != '')
        return;
    const journey = state.users.userJourney;
    if (journey) {
        //@ts-ignore
        const userPlg = !actions.users.getAuthPermissions(true);
        const doneFirstSearch = journey.user_journey.includes('primeira_busca_realizada');
        const doneFirstExportation = journey.user_journey.includes('primeira_exportacao_interna_realizada');
        const isNewAccount = journey.is_new_account;
        state.search.updateDataPopUp.doneFirstSearch = doneFirstSearch;
        if (userPlg && isNewAccount && !doneFirstExportation) {
            state.search.activeModal = ActiveModal.updateAccountDataModal;
            const updateAccountDataModalIsActive = state.search.activeModal === ActiveModal.updateAccountDataModal;
            if (updateAccountDataModalIsActive)
                state.search.updateDataPopUp.showModal = true;
            else
                state.search.updateDataPopUp.showModal = false;
        }
        else {
            if (state.search.activeModal !== '' &&
                state.search.activeModal === ActiveModal.updateAccountDataModal)
                state.search.activeModal = '';
            state.search.updateDataPopUp.showModal = false;
        }
    }
};
export const clearActiveStatesModal = ({ state }) => {
    state.search.showAdvancedFilterModal = false;
    state.search.showCreateAutomaticExportList = false;
    state.search.updateDataPopUp.showModal = false;
    state.search.activeModal = '';
};
export const setIsConsultHistory = ({ state: { search: state } }, value) => {
    state.isConsultHistory = value;
};
export const renameHistory = async ({ effects: { search: effects } }, data) => {
    //@ts-ignore
    const result = await effects.api.renameHistory(
    //@ts-ignore
    data.nameHistory, 
    //@ts-ignore
    data.idHistory);
    return result;
};
export const setShowMakeDecision = ({ state: { search: state } }, value) => {
    state.showMakeDecision = value;
};
export const getDecisorsHistorySearch = async ({ effects }, dataSearchHistory) => {
    const result = await effects.search.api.getDecisorsHistorySearch(dataSearchHistory.Idhistory, dataSearchHistory.selectedCount);
    return result;
};
export const buildExistsFilters = ({ state: { search: state }, }) => {
    const query = state.query;
    const propsToCheck = [
        'additional_data_atlas.lawsuits',
        'decision_makers_lkd_m5.lkd_url',
        'decision_makers_lkd_m5.mchecker_email',
    ];
    propsToCheck.forEach(prop => {
        if (Object.prototype.hasOwnProperty.call(query.search.match, prop) ||
            Object.prototype.hasOwnProperty.call(query.search.match, `${prop}@exists`)) {
            if (!query.search.exists) {
                query.search.exists = [];
            }
            query.search.exists.push(prop);
            delete query.search.match[prop];
            delete query.search.match[`${prop}@exists`];
        }
    });
    return state.query;
};
